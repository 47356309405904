<template>
  <div class="container Nunito" style="max-width: 100%">
      <section class="section headerSection">
        <div class="container has-text-centered">
          <h1 class="title is-2 noselect">Enpower your community!</h1>
          <h1 style="margin-bottom:0px" class="subtitle has-text-white is-5 noselect">Upgrade to premium to enchant your server!</h1>
        </div>
      </section>
    <section class="section has-text-centered" style="">
      <div class="columns is-centered">
        <div class="hidden circles md:block">
        <div style="transform: scale(1);"></div>
        <div style="transform: scale(1);"></div>
        <div style="transform: scale(1);"></div>
        <div style="transform: scale(1);"></div>
        <div style="transform: scale(1);"></div>
      </div>
        <div class="column is-four-fifths" style="z-index: 99;">
          <div class="columns">
            <div class="column premium">
              <div class="box">
                <h2 class="subtitle has-text-white">Super (2 Servers)</h2>
                <h1 class="title has-text-white">$3/m</h1>
                <b-button class="button" icon-left="dollar-sign" size="is-medium" type="is-primary" tag="a" :href="url">Join</b-button>
              </div>
            </div>

            <div class="column premium">
              <div class="box">
                <h2 class="subtitle has-text-white" style="padding-top: 25px;">Super Plus (5 Servers)</h2>
                <h1 class="title has-text-white">$6/m</h1>
                <b-button class="button" icon-left="dollar-sign" size="is-medium" type="is-primary" tag="a" :href="url">Join</b-button>
              </div>
            </div>

            <div class="column premium">
              <div class="box">
                <h2 class="subtitle has-text-white">Very Super Plus (10 Servers)</h2>
                <h1 class="title has-text-white">$15/m</h1>
                <b-button class="button" icon-left="dollar-sign" size="is-medium" type="is-primary" tag="a" :href="url">Join</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a :href="url">Or Get Our User Premium ($1/m)</a>
    </section>
    <Features />
  </div>
</template>

<script>
  import Features from '@/views/features.vue'
  export default {
    name: 'Premium',
    components: {
      Features
    },
    data () {
      return {
        url: 'https://www.buymeacoffee.com/GraceBot'
      }
    },
    created () {
    },
    methods: {
    }
  }
</script>

<style scoped>
  .premium {
    text-align: center !important;
  }
  .premium > .box {
    background-image: var(--is-premium-1);
    min-height: 225px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important;
    -webkit-transition: .4s ease-out;
    transition: .4s ease-out;
  }

  .premium:not(:nth-of-type(2)) > .box {
    background: var(--main-color-bg-3) !important;
    margin-top: 25px;
    min-height: 150px;
  }

  @media screen and (max-width: 750px) {
    .premium:not(:nth-of-type(2)) > .box {
      margin-top: 0px;
    }
  }

  .button {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important;
    transition: .4s ease-out;
  }

  .tag {
    margin-bottom: 15px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important;
  }

</style>

<style scoped>

.circles {
  pointer-events: none;
  position: absolute;
  height: 57.5%;
  width: 100%;
  left: 0;
  top: 0;
}

.hidden {
  display: none;
}

.circles {
    pointer-events: none;
    position: absolute;
    height: 57.5%;
    width: 100%;
    left: 0;
    top: 0;
}
.circles div {
    border-radius: 100%;
    position: absolute;
    opacity: 0.5;
}
.circles div:first-child {
    background-image: linear-gradient(to top left, var(--stops));
    --from: #6366f1;
    --stops: var(--from), var(--to, rgba(99, 102, 241, 0));
    --stops: var(--from), #4f46e5, var(--to, rgba(79, 70, 229, 0));
    --to: #4338ca;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    height: 5vw;
    width: 5vw;
    left: 25vw;
    top: 7.5vw;
}
.circles div:nth-child(2) {
    background-image: linear-gradient(to top left, var(--stops));
    --from: #10b981;
    --stops: var(--from), var(--to, rgba(16, 185, 129, 0));
    --stops: var(--from), #059669, var(--to, rgba(5, 150, 105, 0));
    --to: #047857;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    height: 12.5vw;
    width: 12.5vw;
    left: 12.5vw;
    top: 12.5vw;
}
.circles div:nth-child(3) {

    background-image: linear-gradient(to top left, var(--stops));
    --from: #6b7280;
    --stops: var(--from), var(--to, rgba(107, 114, 128, 0));
    --stops: var(--from), #4b5563, var(--to, rgba(75, 85, 99, 0));
    --to: #374151;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    height: 27.5vw;
    width: 27.5vw;
    left: -13vw;
    top: -13vw;
}
.circles div:nth-child(4) {
    background-image: linear-gradient(to top left, var(--stops));
    --from: #f59e0b;
    --stops: var(--from), var(--to, rgba(245, 158, 11, 0));
    --stops: var(--from), #d97706, var(--to, rgba(217, 119, 6, 0));
    --to: #b45309;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    height: 7.5vw;
    width: 7.5vw;
    right: 20vw;
    top: 10vw;
}
.circles div:nth-child(5) {
    background-image: linear-gradient(to top left, var(--stops));
    --from: #ef4444;
    --stops: var(--from), var(--to, rgba(239, 68, 68, 0));
    --stops: var(--from), #dc2626, var(--to, rgba(220, 38, 38, 0));
    --to: #b91c1c;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    height: 27.5vw;
    width: 27.5vw;
    bottom: -15vw;
    right: -17.5vw;
    z-index: 0;
}

@media (min-width: 768px) {
  .md\:block {
      display: block;
  }
}
</style>
